


$primary-font-color: black;
$button-font-color: white;

$primary-color: #E55123;
$secondary-color: silver;

$grey-box-background: #eee;
$grey-box-border: #ccc;


// bootstrap overrides

$grid-columns: 24;

// https://github.com/twbs/bootstrap/issues/23112
$theme-colors: ("primary": $primary-color);

$btn-primary-color: $button-font-color;
$btn-primary-bg: $primary-color;
$btn-primary-border: $primary-color;


.container-fluid {
  max-width: 1300px; }
